div.price-card {
  margin-right: 1rem;
  filter: drop-shadow(0px 4px 0px $rgbacolor_beige_ombre);

  .price {
    font-weight: bold;
  }

  .marketing-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  &-inner {
    @include bevel(5px);
    @include padding-vertical(4rem);
    margin: 1rem;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    text-align: center;

    &-month {
      background-color: white;

      h2,
      .marketing-title {
        color: $hexcolor_turquoise;
      }

      .price {
        color: $hexcolor_red;
      }
    }

    &-year {
      background-color: $hexcolor_red !important;
      color: white;

      button {
        background-color: white;
        color: $color_button;
      }

      h2 {
        color: white;
      }
    }
  }

  &-month {
    .deal-tag {
      display: none;
    }
  }

  &-year {
    .deal-tag {
      width: 7.5rem;
      height: 7.5rem;
      background: url("../../assets/Backgrounds/green_tag_background.svg");
      background-size: 100% 100%;
      position: absolute;
      top: -2rem;
      right: -2rem;
      transform: rotate(349deg);
      color: white;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .value {
        font-weight: bold;
        font-size: 2rem;
      }
    }
  }

  &-footer {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;

    align-items: center;

    &-checkmark {
      display: flex;
      justify-content: center;
      align-items: center;

      &-img {
        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
