@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?f26ejv");
  src: url("fonts/icomoon.eot?f26ejv#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?f26ejv") format("truetype"),
    url("fonts/icomoon.woff?f26ejv") format("woff"),
    url("fonts/icomoon.svg?f26ejv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-backarrow:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e900";
  display: inline-block;
  transform: rotate(180deg);
}
.icon-uparrow:before {
  content: "\e900";
  display: inline-block;
  transform: rotate(90deg);
  transition-duration: 0.5s;
}
.icon-downarrow:before {
  content: "\e900";
  display: inline-block;
  transform: rotate(-90deg);
  margin-left: -0.5px;
  transition-duration: 0.5s;
}
.icon-clock:before {
  content: "\e901";
}
.icon-cross:before {
  content: "\e902";
}
.icon-plus:before {
  content: "\e903";
}
.icon-tick:before {
  content: "\e904";
}
.icon-hide:before {
  content: "\e905";
}
.icon-mail:before {
  content: "\e906";
}
.icon-show:before {
  content: "\e907";
}
.icon-edit:before {
  content: "\e908";
}
.icon-empty:before {
  content: "\e909";
}
.icon-alert-en:before {
  content: "\e90a";
}
.icon-alert-dis:before {
  content: "\e90b";
}
.icon-delete:before {
  content: "\e90c";
}
.icon-copy:before {
  content: "\e90d";
}
.icon-fb:before {
  content: "\e90e";
}
.icon-apple:before {
  content: "\e90f";
}
.icon-cog:before {
  content: "\e910";
}

.icon-none {
  width: 1em;
  height: 1em;
}

.icon-md {
  font-size: 1.5rem;
}

.icon-lg {
  font-size: 2rem;
}

.task-icon {
  max-width: 50px;
}

.profile-icon {
  max-width: 25px;
}
